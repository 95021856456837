<template>
    <div>
        <v-card
            class="mb-5"
        >
            <h2 class="pl-2">
            {{ $t('groups.details') }}
            </h2>
          <v-btn
              color="red"
              class="float-right mr-10 white--text"
              @click="deleteGroup()"
            >
            {{ $t('common.delete') }}
            <v-icon class="ml-2">mdi-delete</v-icon>
            </v-btn>
            <v-btn
              color="blue"
              dark
              class="float-right mr-10 mb-5"
              @click.stop="editGroup()"
            >
            {{ $t('common.edit' )}}
          </v-btn>
          <div style="clear:both"></div>
          <div class="ml-5 pb-5">

            <div class="ml-2 mt-2">
              <b>{{ $t('common.name') }}</b>
            <v-card-text>
              {{ group.name}}
            </v-card-text>
            <br>
            <b>{{ $t('common.description') }}</b>
            <v-card-text>
                {{group.description}}
            </v-card-text>
            </div>
          </div>
        </v-card>

        <Traders
            :customTitle="'Użytkownicy należący do grupy'"
            :groupId="this.group.id"
        ></Traders>

        <add-or-edit-group-dialog
            ref="addOrEditGroupDialog"
            @submit="getDataAsync"
        ></add-or-edit-group-dialog>

        <confirm-delete-dialog
          endPoint="/Group/DeleteGroup"
          ref="confirmDeleteDialog"
          @submit="getDataAsync"
        ></confirm-delete-dialog>
    </div>
</template>
<script>

import Traders from '@/views/UserManagement/Traders'
import AddOrEditGroupDialog from '@/views/Groups/AddOrEditGroupDialog'
import ConfirmDeleteDialog from '@/components/Dialogs/ConfirmDeleteDialog'

export default {
  name: 'GroupDetails',
  components: {
    Traders,
    AddOrEditGroupDialog,
    ConfirmDeleteDialog
  },
  data () {
    return {
      group: {
        id: '',
        name: '',
        description: ''
      }
    }
  },
  async created () {
    this.group.id = this.$route.params.id
    await this.getDataAsync()
  },
  methods: {
    async getDataAsync () {
      const result = await this.$http.get(`/Group/GetGroup?Id=${this.group.id}`)
      this.group = result.data
    },
    editGroup () {
      this.$refs.addOrEditGroupDialog.dialog = true
      this.$refs.addOrEditGroupDialog.group = JSON.parse(JSON.stringify(this.group))
    },
    deleteGroup () {
      this.$refs.confirmDeleteDialog.dialog = true
      this.$refs.confirmDeleteDialog.message = this.group.name
      this.$refs.confirmDeleteDialog.id = this.group.id
    }
  }
}
</script>
